@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url('../public/fonts/Inter_18pt-Regular.ttf');
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url('../public/fonts/Inter_18pt-Light.ttf');
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url('../public/fonts/Inter_18pt-Medium.ttf');
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url('../public/fonts/Inter_18pt-SemiBold.ttf');
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url('../public/fonts/Inter_18pt-Bold.ttf');
}


@layer base {

  html {
    font-size: 16px;
  }

  @media (max-width: 1390px) {
    html {
      font-size: 15px;
    }
  }

  @media (max-width: 1273px) {
    html {
      font-size: 14px;
    }
  }

  @media (max-width: 1023px) {
    html {
      font-size: 16px;
    }
  }

  body {
    font-family: "Inter";
  }


  /* typography */
  .lp-text-display-mobile {
    @apply text-[2.125rem] font-bold;
  }

  .lp-text-display-4 {
    @apply text-[3.125rem] font-bold;
  }

  .lp-text-display-3 {
    @apply text-[2.5rem] font-bold
  }

  .lp-text-display-2 {
    @apply text-[2rem] font-bold;
  }

  .lp-text-display-1 {
    @apply text-[1.875rem] font-bold;
  }

  .lp-text-headline-2 {
    @apply text-[1.75rem] font-semibold;
  }

  .lp-text-headline-1 {
    @apply text-[1.5rem] font-semibold;
  }

  .lp-text-title-2 {
    @apply text-[1.375rem] font-medium;
  }

  .lp-text-title-1 {
    @apply text-[1.25rem] font-medium;
  }

  .lp-text-subhead-2 {
    @apply text-[1.125rem] font-bold;
  }

  .lp-text-subhead-1 {
    @apply text-[1rem] font-medium;
  }

  .lp-text-body-2 {
    @apply text-[1rem];
  }

  .lp-text-body-1 {
    @apply text-[0.875rem];
  }

  .lp-text-caption {
    @apply text-[0.75rem];
  }

  .lp-text-button {
    @apply text-[0.875rem] font-medium;
  }

  .lp-text-caption-mobile {
    @apply text-[0.625rem];
  }

  .circle {
    height: 6.75rem;
    width: 6.75rem;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .indicator-hero {
    padding-bottom: 2rem !important;
  }

  .indicator-mitra {
    padding-top: 5rem !important;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    /* Set the top to 50% to cover the bottom half */
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: 1;
  }

  .line-separator {
    height: 2px;
    /* Adjust the height of the line */
    background-color: #085BDB;
    /* Set the background color */
  }
}

.content-html p a {
  color: #045cb4;
  cursor: pointer;
  line-height: 1.563rem;
}

/* .content-html li {
  padding-left: 1rem;
} */

.content-html ol {
  list-style-type: decimal;
  /* or circle etc */
  padding-left: 1.2rem;
}

.content-html ul {
  list-style-type: disc;
  /* or circle etc */
  padding-left: 1.2rem;
}

.content-html input {
  display: flex;
  border: rgb(125, 125, 125);
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;

}

.flex-parent {
  display: flex;
  flex-direction: row;
}

.laznastimeline>* {
  box-sizing: border-box;
}

/* The actual laznastimeline (the vertical ruler) */
.laznastimeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual laznastimeline (the vertical ruler) */
.laznastimeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: blue;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.laznastimeline_container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;

}

/* The circles on the laznastimeline */
.laznastimeline_container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: white;
  border: 4px solid blue;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.laznastimeline_left {
  left: 0;
}

/* Place the container to the right */
.laznastimeline_right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.laznastimeline_left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid blue;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent blue;
}

/* Add arrows to the right container (pointing left) */
.laznastimeline_right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid blue;
  border-width: 10px 10px 10px 0;
  border-color: transparent blue transparent transparent;
}

/* Fix the circle for containers on the right side */
.laznastimeline_right::after {
  left: -13px;
}

/* The actual content */
.laznastimeline_content {
  padding: 20px 30px;
  background-color: blue;
  color: white;
  position: relative;
  border-radius: 6px;
}

.flex-child-4 {
  width: 33%;
}

.flex-child-6 {
  width: 50%;
}

.flex-child-8 {
  width: 66%;
}

/* Media queries - Responsive laznastimeline on screens less than 600px wide */
@media screen and (max-width: 1023px) {

  /* Place the timelime to the left */
  .laznastimeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .laznastimeline_container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .laznastimeline_container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .laznastimeline_left::after,
  .laznastimeline_right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .laznastimeline_right {
    left: 0%;
  }
}

@media only screen and (max-width: 1023px) {
  .flex-parent {
    display: flex;
    flex-direction: column;
  }

  [class^="flex-child-"] {
    width: 100%;
  }
}